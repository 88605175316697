import { memo } from "react";

import { useState } from "react";

import { GlHero } from "gitlanding/GlHero/GlHero";
import { GlArticle } from "gitlanding/GlArticle";

import { GlButton } from "gitlanding/utils/GlButton";
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';

import { GlCards } from "gitlanding/GlCards";
import { GlLogoCard } from "gitlanding/GlCards/GlLogoCard";
import { GlCodeBlock } from "gitlanding/GlCodeBlock"; //aded
import { GlCheckList } from "gitlanding/GlCheckList"; //aded
import {  GlCards as GlLogoCardSection } from "gitlanding/GlCards/GlCards"; //aded


import {GlLogo} from "gitlanding/utils/GlLogo"; //added

import { declareComponentKeys, useTranslation } from "i18n";
import heroPng from "assets/img/hero.png";
import articlePng from "assets/img/home-article.png";
import balloonIcon from "assets/icons/balloon.png";
import drawioIcon from "assets/icons/drawio.png";
import githubIcon from "assets/icons/github.png";
import plusIcon from "assets/icons/plus.png";
import rocketIcon from "assets/icons/rocket-chat.png";
import tchapIcon from "assets/icons/tchap.png";

import  svg_test  from    '@mui/icons-material/CheckBox';
import ShieldIcon from '@mui/icons-material/Shield';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import CodeIcon from '@mui/icons-material/Code';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';

import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import MailLockIcon from '@mui/icons-material/MailLock';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';

import h1_image from "assets/img/h1.png";
import h2_image from "assets/img/h2.png";


import discord_logo from "assets/icons/discord.png";
import reddit_logo from "assets/icons/reddit.png";

import matrix_logo from "assets/icons/Matrix_icon.svg.png";
import twiiter_logo from "assets/icons/twitter-logo-on-black-background.png";

import youtube_logo from "assets/icons/youtube.png";
import mastadoon_logo from "assets/icons/mastodon.png";

//---#######################################  SOCIAL MEDIA ICON

import Button from '@mui/material/Button';



import Avatar from '@mui/material/Avatar';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import {
	EmailShareButton,
	FacebookShareButton,
	HatenaShareButton,
	InstapaperShareButton,
	LineShareButton,
	LinkedinShareButton,
	LivejournalShareButton,
	MailruShareButton,
	OKShareButton,
	PinterestShareButton,
	PocketShareButton,
	RedditShareButton,
	TelegramShareButton,
	TumblrShareButton,
	TwitterShareButton, TwitterIcon ,
	ViberShareButton,
	VKShareButton,
	WhatsappShareButton,
	WorkplaceShareButton
  } from "react-share";


//---########################################


//-------------------------------------



/*
const FormApp = () => {
  const [term, setTerm] = useState('');

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    // Preventing the page from reloading
    event.preventDefault();

    // Do something 
    alert(term);
  }

  return (
    <div className="container">
      <form onSubmit={submitForm}>
        <input
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          type="text"
          placeholder="Enter a term"
          className="input"
        />
        <button type="submit" className="btn">Submit</button>
      </form>
    </div>
  );
};

export default FormApp;*/

import { SnackbarProvider, enqueueSnackbar } from 'notistack';  //for popup alert

import { useForm, SubmitHandler } from "react-hook-form";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
export function DescriptionAlerts() {
	//alert("Alert should show");
	enqueueSnackbar('That was easy!', { variant: 'warning'  ,  autoHideDuration: 3000 })
	return (
	  <Stack sx={{ width: '100%' }} spacing={2}>
		<Alert severity="error">
		  <AlertTitle>Error</AlertTitle>
		  This is an error alert — <strong>check it out!</strong>
		</Alert>
		<Alert severity="warning">
		  <AlertTitle>Warning</AlertTitle>
		  This is a warning alert — <strong>check it out!</strong>
		</Alert>
		<Alert severity="info">
		  <AlertTitle>Info</AlertTitle>
		  This is an info alert — <strong>check it out!</strong>
		</Alert>
		<Alert severity="success">
		  <AlertTitle>Success</AlertTitle>
		  This is a success alert — <strong>check it out!</strong>
		</Alert>
	  </Stack>
	);
  }


type FormValues = {
	email_query: string;
};

export default function FormApp() {
  const { register, handleSubmit } = useForm<FormValues>();  

  const onSubmit: SubmitHandler<FormValues> = async(data) =>{

	//e.preventDefault();

	console.log(data);

	const requestOptions = {
		crossDomain:true,
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data)
	};

	const response =  await fetch( "https://vk571cvntb.execute-api.us-east-1.amazonaws.com/dev/email_query" , requestOptions); //"https://sensei.alwaysdata.net", requestOptions);
	const jsonData =  await response.json();

	console.log(jsonData);
	//DescriptionAlerts();
	//alert("Securly Added your email "+jsonData);

	if( jsonData.valid === false){
		enqueueSnackbar( "ERROR: " + jsonData.message , { variant: 'error' , autoHideDuration: 3000 }) ;}
	else{
		enqueueSnackbar('Success: ' + jsonData.message , { variant: 'success' , autoHideDuration: 3000 }) ;}

	}

  return (
	<div>
    <form onSubmit={ (e) => {e.preventDefault() ;handleSubmit(onSubmit)()}  }>
         <TextField label="E-Mail" variant="outlined" type="email" {...register("email_query")}  />							
    </form>
	<div><br/> <GlButton onClick={(e) => {e.preventDefault() ;handleSubmit(onSubmit)()}}> Notify Me ! </GlButton> </div>

	<SnackbarProvider />
	
	</div>
  );
}
  

//------------------------------------



export const Home = memo(() => {
	const { t } = useTranslation({ Home });
	return (
		<>
			<GlHero
				title={t("heroTitle")}
				subTitle={t("heroSubtitle")}
				illustration={{
					"type": "image",
					//"src": heroPng,
					"src": h1_image,
					"hasShadow": false
				}}
				hasLinkToSectionBellow={true}
			/>

			<GlArticle
				title={t("articleTitle")}
				body={t("articleBody")}
				//buttonLabel={t("articleButtonLabel")}
				//buttonLink={{
				//	"href": "https://example.com",
				//}}
				illustration={{
					"type": "image",
					//"src": articlePng,
					"src": h2_image,
					"hasShadow": false
				}}
				hasAnimation={true}
				illustrationPosition="left"
			/>

			
			<GlArticle
				title='Bare Minimum Data'

				/*body="Say goodbye to intrusive data collection and hello to true privacy with Wolf Codex.				
				We believe your personal information should only belong to you, and that's why 
				we store only the bare minimum necessary to provide our services. 
				
				Trust us to keep your data secure and private, with no hidden agendas or prying eyes."
				*/

				body ={ 'Say goodbye to intrusive data collection and hello to true privacy with Wolf Codex.' + '\n\n' +
				
				"We believe your personal information should only belong to you, and that's why we store only the bare minimum necessary to provide our services."+ '\n\n' +
				"Trust us to keep your data secure and private, with no hidden agendas or prying eyes." 

			
				}


				
				//buttonLabel='Button Label'
				//buttonLink={{
				//	"href": "https://example.com/",
				//}}


				illustration={{
					"type": "custom component",
					"Component": () => {
						return (
							<GlCodeBlock
								programingLanguage="typescript"
								text={`//-----------|| Minimum User Data ||-----------//
								\nuser_uuid = "321db81a-0e3e-4033-93d3-1c029aac0d08"\nuser_creation = 1677792783\nstorage_plan = "100G"\nstorage_used = 0.854\ndata_expire = 1685741583\nuser_expire = 1739337469\nbandwith = "200G"\nbandwith_used = 0.567\nlast_connection=1680471183
								\n//-----------|| Optional User Data ||-----------//
								\nemail = "john.doe@email.com"`

								}
								//\n//---------------|| Files ||---------------//
								//\n[ENCRYPTED NAME] : 20G\n....\n....\n....\n[ENCRYPTED NAME] : 150M`}
							/>
						);
					},
				}}
			/>




			<GlCheckList
				heading="Why Wolf Codex ?"
				hasAnimation={true}
				elements={[
					{
						"title": "Private",
						"description": `Experience complete privacy with our commitment to minimizing stored metadata and maximizing security measures.`,
						"IconOverride": EnhancedEncryptionIcon,
					},
					{
						"title": "Secure",
						"description": `Unlock peace of mind with unparalleled security measures for your confidential data.`,
						"IconOverride": ShieldIcon,

					},
					{
						"title": "Simple",
						"description": `Experience effortless security with our straightforward and user-friendly platform.`,
						"IconOverride": TouchAppIcon,

					},
					{
						"title": "Fast",
						"description": `Experience lightning-fast access to your data, with our optimized infrastructure designed for speed and efficiency.`,
						"IconOverride": ElectricBoltIcon,

					},
					{
						"title": "Synced",
						"description": `Stay seamlessly connected with your data, always in sync across all your devices.`,
						"IconOverride": CloudDoneIcon,

					},
					{
						"title": "Open",
						"description": `Experience transparency and trust with our fully client-side open source platform, giving you the power to take control of your data.`,
						"IconOverride": CodeIcon,
					},
				]}
			/>

			


			<GlArticle
				title={"Get Notified of Launch"}
				//<input type="text" name="search_query" /> 
				
				body={  
					
					<Box
					 sx={{
						//backgroundColor: { xs: "red", sm: "pink" , md: "green", lg: "blue" , xl: "yellow" }, 
						width:  { xs: 350, sm: 500 , md: 800, lg: 900 , xl: 1000 }, 
						}}
									>
					<Box
					sx={{
						'& .MuiTextField-root': {width:  { xs: 350, sm: 500 , md: 800, lg: 900 , xl: 1000 } }
					  //width: 800,
					 // minWidth : '10%',
					 // maxWidth: '100%',
					}}
					//component="form"
					//sx={{
					//  '& .MuiTextField-root': { m: 1, width: '100ch' },
					//}}
					//noValidate
					//autoComplete="off"
				  >
					<br/>
					
					<FormApp></FormApp>

					<GlCheckList 
				//heading="We Respect your Email Privacy"
				hasAnimation={true}
				elements={[
					{
						"title": "No Third-Party Sharing",
						"description": `We value your privacy and will never share your personal information with any third party, ensuring that your data remains safe and secure.`,
						"IconOverride": NotificationsOffIcon,
					},
					{
						"title": "No SPAM. EVER",
						"description": `We understand that your time is valuable. You can trust that we will never send you any spam messages or emails.`,
						"IconOverride": MailLockIcon,

					},
					{
						"title": "Notification of Launch Only",
						"description": `We are committed to providing a transparent and trustworthy experience to our users. We only use your email address to notify you of our launch and keep you updated on our progress.`,
						"IconOverride": RocketLaunchIcon,

					},
					{
						"title": "Stay in the Loop",
						"description": `By signing up for our notification list, you'll be the first to know when we go live. Stay in the loop with our latest updates and be among the first to access our new service.`,
						"IconOverride": FlipCameraAndroidIcon,

					},
				]}
			/>
				  </Box>

				  </Box>
				  }
				  

				//buttonLabel={t("articleButtonLabel")}
				//buttonLink={{
				//	"href": "https://example.com",
				//}}
				//
				//illustration={{
			//		"type": "image",
					//"src": articlePng,
			//		"src": h2_image,
			//		"hasShadow": false
			//	}}
				hasAnimation={true}
				illustrationPosition="left"
			/>

			<GlArticle 
				//title={"Join our community"}
				//<input type="text" name="search_query" /> 
				
				body={  	
					<Box
					 sx={{
						p : 5,
						display: 'flex',
						justifyContent: 'space-evenly',
						flexWrap: 'wrap',
						//marginBlock: 50,
					 	//justifyContent: 'space-around' ,
						//backgroundColor: '#cbcbcb',
						backgroundColor: '#000000',
						
						//backgroundColor: { xs: "red", sm: "pink" , md: "green", lg: "blue" , xl: "yellow" }, 
						width:  { xs: 300, sm: 450 , md: 450, lg: 450 , xl: 450 }, 
						
						
						//'& .MuiTextField-root': {width:  { xs: 350, sm: 500 , md: 800, lg: 900 , xl: 1000 } }
					  //width: 800,
					 // minWidth : '10%',
					 // maxWidth: '100%',
					}}
					//component="form"
					//sx={{
					//  '& .MuiTextField-root': { m: 1, width: '100ch' },
					//}}
					//noValidate
					//autoComplete="off"
				  >
					
						<Button href="https://mastodon.social/@wolfcodex" target="_blank" rel="noopener noreferrer" variant="text" size="small" style={{display: "flex", flexDirection: "column"}} >
						<img src={mastadoon_logo} width="50" alt="folder"/>
						<label></label>
						</Button>

						<Button href="https://www.youtube.com/@Wolf-codex" target="_blank" rel="noopener noreferrer" variant="text" size="small" style={{display: "flex", flexDirection: "column"}} >
						<img src={youtube_logo} width="50" alt="folder"/>
						<label></label>
						</Button>

						<Button href=" https://matrix.to/#/#wolfcodex:matrix.org" target="_blank" rel="noopener noreferrer" variant="text" size="small" style={{display: "flex", flexDirection: "column"}} >
						<img src={matrix_logo} width="50" alt="folder"/>
						<label></label>
						</Button>
	

						<Button href="https://www.reddit.com/r/wolfcodex" target="_blank" rel="noopener noreferrer" variant="text" size="small" style={{display: "flex", flexDirection: "column"}} >
						<img src={reddit_logo} width="50" alt="folder"/>
						<label></label>
						</Button>
															

						<Button  href="https://twitter.com/wolf_codex" target="_blank" rel="noopener noreferrer" variant="text" size="small" style={{ display: "flex", flexDirection: "column"}} >
						<img src={twiiter_logo} width="50" alt="folder"/>
						<label></label>
						</Button>	


						<Button href="https://discord.gg/YBgpz2MEHS" target="_blank" rel="noopener noreferrer" variant="text" size="small" style={{display: "flex", flexDirection: "column"}} >
						<img src={discord_logo} width="50" alt="folder"/>
						<label></label>
						</Button>		

						
						
    

					</Box>
				}
				hasAnimation={true}
				//illustrationPosition="left"
			/>

			
		</>
	);
});

export const { i18n } = declareComponentKeys<
	| "heroTitle"
	| "heroSubtitle"
	| "articleTitle"
	| "articleBody"
	| "articleButtonLabel"
	| "card1Title"
	| "card2Title"
	| "card3Title"
	| "card1Paragraph"
	| "card2Paragraph"
	| "card3Paragraph"
>()({ Home });